import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Carousel from "../../Components/Carousel";
import "./index.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Lazy } from "swiper";
function Partners() {
  return (
    <div className="Partners" id="Partners">
      <ScrollAnimation delay={100} animateIn="fadeIn">
        <div className="container">
          <div className="row">
            <div className="title">
              <h1>Trusted By</h1>
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation delay={100} animateIn="fadeIn">
        <div className="container">
          <Swiper
            spaceBetween={10}
            slidesPerView={5}
            autoplay={{
              delay: 1,
            }}
            speed={2000}
            modules={[Autoplay, Pagination, Navigation, Lazy]}
            loop={true}
            initialSlide={0}
            centeredSlides={false}
            breakpoints={{
              960: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            allowTouchMove={false}
            grabCursor={false}
          >
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059923237947056268/image0.jpg"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://cdn.discordapp.com/attachments/1035139393150787675/1059923414850228264/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059923454993903747/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059923964236939354/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059925016914960474/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059925214131142737/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927201908260934/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927306187067454/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927447581237379/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927558608662538/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927713755963412/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927868072804423/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059927962834718791/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1059928065247023254/image0.jpg"
                  alt="placeholder"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068220541426749440/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068221390240620727/image-removebg-preview_1.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://cdn.discordapp.com/attachments/1035139393150787675/1068221469835923476/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068221630930747392/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068221856961790053/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068222011958116433/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068222365273702461/image-removebg-preview.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068222521528291398/5583.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068223177412591626/image-removebg-preview_2.png"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ padding: 8 }}>
                <img
                  src="https://media.discordapp.net/attachments/1035139393150787675/1068223310602719232/Bitfinex-Logo.png?width=1007&height=671"
                  style={{ width: "150px" }}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Partners;
