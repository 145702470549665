import React, { useState } from "react";
import "./Footer.css";
import logo2 from "../assets/logo2.svg";
import { decode } from "html-entities";

function Footer({ status, message, onValidated }) {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };
  return (
    <div className="Footer" id="Footer">
      <footer className="bg-dark">
        <div className="container py-5">
          <div className="row "></div>
        </div>

        <div className="bg-dark py-4">
          <div className="container text-center">
            <p className="text-muted mb-0 py-2">
              © 2022 Premium + Agency All rights reserved. made with ❤️ by{" "}
              <a href="https://discord.gg/Mj4atW5Hjn">Tenreck</a> &{" "}
              <a href="https://jaiveer.tech">Jaiveer</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
