import React from "react";
import "./App.css";
import Home from "./Screens/Home";
import Navbar from "./Components/Navbar";
import Scope from "./Screens/Scope";
import Projects from "./Screens/Projects";
import Partners from "./Screens/Partners";
import Contact from "./Screens/Contact";
import Footer from "./Components/Footer";
import Newsletter from "./Components/Newsletter";
import { createBrowserRouter, RouterProvider, Route,BrowserRouter as Routers, Routes} from "react-router-dom";
import ErrorPage from "./Components/404";
import "animate.css/animate.min.css";
function App() {



  const Main = () => {
    return (
      <div>
          <Home />
          <Scope />
          <Projects />
          <Partners />
          <Contact />

        </div>
    )
  }

  return (
    <div className="App">
      <Navbar />

      <Routers>
        <Routes>
          <Route path="/" element={<Main   />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Routers>

    </div>
  );
}

export default App;
