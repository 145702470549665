import React from "react";
import "./index.css";
import scope1 from "../../assets/scope2.svg";
import Card from "../../Components/card";
import world from "../../assets/world.svg";
import investing from "../../assets/investing.svg";
import portfolio from "../../assets/portfolio.svg";
import objective from "../../assets/objective.svg";
import Carousel from "../../Components/Carousel";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import VisibilitySensor from "react-visibility-sensor";

function Scope() {
  return (
    <div className="Scope" id="about">
      <div className="scope1">
        <div className="container">
          <img src={scope1} alt="scope1" />
          <div className="discription">
            <ScrollAnimation delay={100} animateIn="fadeIn">
              <h2 className="scope-desc">MEET PREMIUM PLUS</h2>
            </ScrollAnimation>
            <ScrollAnimation delay={100} animateIn="fadeIn">
              <p>
                Premium + Agency is a full-service digital marketing agency
                specialized in the crypto industry. We provide comprehensive
                marketing solutions to help crypto businesses reach their target
                audiences and grow. Our services include Fundraising, SEO,
                content creation, social media management, ad campaigns,
                influencer marketing, and more.
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="scope2" id="scope">
        <div className="container">
          <div className="scope-title">
            <ScrollAnimation delay={100} animateIn="fadeIn">
              <h1>Our Services</h1>
            </ScrollAnimation>
          </div>

          <div className="box">
            <div className="carousel">
              <Carousel show="1">
                <ScrollAnimation delay={100} animateIn="fadeIn">
                  <Card
                    index="1"
                    img="https://images.vexels.com/media/users/3/138629/isolated/lists/492213f3a744c6ac3c476fb50ee8df4c-sacred-geometry-with-squares.png"
                    title="Social Media Management"
                    description="At Premium Plus Agency, we understand the importance of maintaining a strong online presence in the crypto industry. That's why we offer top-notch social media management services to help our clients stay connected with their followers and effectively promote their brand. Our team of experts craft engaging and informative content to keep your audience informed about your latest projects and developments. We also handle all aspects of social media management, including strategy, scheduling, and engagement with followers. Trust us to take your social media game to the next level.
"
                  />
                </ScrollAnimation>
                <ScrollAnimation delay={100} animateIn="fadeIn">
                  <Card
                    index="2"
                    img="https://images.vexels.com/media/users/3/138700/isolated/lists/9f72b1738f2018b809dbdfe0402e1a72-star-sacred-geometry.png"
                    title="Fundraising"
                    description="Are you in need of funding for your crypto project? Look no further than Premium Plus Agency. Our team has extensive networks and resources to help secure funding for your company. We have a track record of success in fundraising, and we are confident in our ability to help you achieve your financial goals. Let us assist you in the crucial task of fundraising."
                  />
                </ScrollAnimation>
                <ScrollAnimation delay={100} animateIn="fadeIn">
                  <Card
                    index="3"
                    img="https://images.vexels.com/media/users/3/138506/isolated/lists/efa50d54b013c2b6b23f3cef632f8105-hexagonal-grid-sacred-geometry.png"
                    title="KOL Management"
                    description="Key opinion leaders (KOLs) can be incredibly valuable assets for any crypto company looking to amplify its message. At Premium Plus Agency, we offer KOL management services to help you collaborate with industry influencers and get your brand in front of a larger audience. Our team is skilled at identifying and working with the most influential KOLs in the industry. Let us help you leverage the power of KOLs to take your brand to the next level."
                  />
                </ScrollAnimation>
                <ScrollAnimation delay={100} animateIn="fadeIn">
                  <Card
                    index="4"
                    img="https://images.vexels.com/media/users/3/138654/isolated/lists/c66fc2f0207f4af13586db39cb4d2b6c-triangle-geometric-sacred-geometry.png"
                    title="Advertising"
                    description="At Premium Plus Agency, we know how to effectively promote your brand in the crowded crypto market. Our advertising services include targeted campaigns to reach the right audience and get your message across. Our team has a wealth of experience in crafting compelling advertisements that drive results. With our help, you can take your brand to new heights."
                  />
                </ScrollAnimation>
              </Carousel>
            </div>
            <ScrollAnimation delay={100} animateIn="fadeIn">
              <div className="desktop-card">
                <Card
                  index="1"
                  img="https://images.vexels.com/media/users/3/138629/isolated/lists/492213f3a744c6ac3c476fb50ee8df4c-sacred-geometry-with-squares.png"
                  title="Social Media Management"
                  description="At Premium Plus Agency, we understand the importance of maintaining a strong online presence in the crypto industry. That's why we offer top-notch social media management services to help our clients stay connected with their followers and effectively promote their brand. Our team of experts craft engaging and informative content to keep your audience informed about your latest projects and developments. We also handle all aspects of social media management, including strategy, scheduling, and engagement with followers. Trust us to take your social media game to the next level.
"
                />

                <Card
                  index="2"
                  img="https://images.vexels.com/media/users/3/138700/isolated/lists/9f72b1738f2018b809dbdfe0402e1a72-star-sacred-geometry.png"
                  title="Fundraising"
                  description="Are you in need of funding for your crypto project? Look no further than Premium Plus Agency. Our team has extensive networks and resources to help secure funding for your company. We have a track record of success in fundraising, and we are confident in our ability to help you achieve your financial goals. Let us assist you in the crucial task of fundraising."
                />
                <Card
                  index="3"
                  img="https://images.vexels.com/media/users/3/138506/isolated/lists/efa50d54b013c2b6b23f3cef632f8105-hexagonal-grid-sacred-geometry.png"
                  title="KOL Management"
                  description="Key opinion leaders (KOLs) can be incredibly valuable assets for any crypto company looking to amplify its message. At Premium Plus Agency, we offer KOL management services to help you collaborate with industry influencers and get your brand in front of a larger audience. Our team is skilled at identifying and working with the most influential KOLs in the industry. Let us help you leverage the power of KOLs to take your brand to the next level."
                />
                <Card
                  index="4"
                  img="https://images.vexels.com/media/users/3/138654/isolated/lists/c66fc2f0207f4af13586db39cb4d2b6c-triangle-geometric-sacred-geometry.png"
                  title="Advertising"
                  description="At Premium Plus Agency, we know how to effectively promote your brand in the crowded crypto market. Our advertising services include targeted campaigns to reach the right audience and get your message across. Our team has a wealth of experience in crafting compelling advertisements that drive results. With our help, you can take your brand to new heights."
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="fund">
        <div className="container">
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <div className="row">
              <div className="row">
                <h1>Funds Raised</h1>
              </div>

              <div className="barrier"></div>

              <div className="amount">
                <h2>
                  $
                  <CountUp end={13.4}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  m
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="row">
                <h1>Managing funds</h1>
              </div>
              <div className="barrier"></div>
              <div className="amount">
                <h2>
                  $
                  <CountUp end={4}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  m
                </h2>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
}

export default Scope;
