import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./index.css";

function Home() {
  return (
    <div className="Home">
      <div className="container">
        <h1 className="title">The Biggest Crypto Incubator</h1>
        <p>Web3 business development company</p>
      </div>
      <div className="bottombar">
        <a href="#about">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.619 23.75L24.9998 47.5002L2.38046 23.75"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M25 46.25V3.75" stroke="white" strokeLinecap="round" />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default Home;
