import React, { useCallback, useEffect, useState } from "react";
import "./Navbar.css";
import logo from "../assets/logo.png";

function Navbar() {


const [y, setY] = useState(window.scrollY);
  const [navDisplay, setnavDisplay] = useState("flex");
const [navDisplay1, setnavDisplay1] = useState("flex");
  

const handleNavigation = useCallback(
  (e) => {
    const window = e.currentTarget;
    if (y > window.scrollY) {
      setnavDisplay("flex"); 
      setnavDisplay1('flex')

    } else if (y < window.scrollY) {
      setnavDisplay("none");
      setnavDisplay1("none");
          const menu = document.getElementById("menu-mobile");

          const burger = document.getElementById("burger");
          menu.classList.remove("active");
          burger.classList.remove("active");

    }
    setY(window.scrollY);
  },
  [y]
);

useEffect(() => {
  setY(window.scrollY);
  window.addEventListener("scroll", handleNavigation);

  return () => {
    window.removeEventListener("scroll", handleNavigation);
  };
}, [handleNavigation]);


  const toggle = () => {
    const menu = document.getElementById("menu-mobile");

    const burger = document.getElementById("burger");
    menu.classList.toggle("active");
    burger.classList.toggle("active");

  };
  
  return (
    <div className="Navbar">
      <div
        className="container"
        style={{
          display: navDisplay,
        }}
      >
        <div className="row">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Premium + Agency" />
            </a>
          </div>
          <div className="burger" id="burger" onClick={toggle}>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="menu-mobile" id="menu-mobile">
          <ul onClick={toggle}>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#scope">Our Services</a>
            </li>
            <li>
              <a href="#Projects">Recent Clients</a>
            </li>
            <li>
              <a href="#Partners">Partners</a>
            </li>
            <li>
              <a href="#Contact">Contact</a>
            </li>
          </ul>

          {/* <li>
            <a href="https://jaiveer.tech">Blockchain accelerator</a>
          </li> */}
        </div>
        <div className="menu">
          <ul>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#scope">Our Services</a>
            </li>
            <li>
              <a href="#Projects">Recent Clients</a>
            </li>
            <li>
              <a href="#Partners">Partners</a>
            </li>
            <li>
              <a href="#Contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="bottombutton container">
        <a href="https://jaiveer.tech">Blockchain accelerator</a>
      </div> */}
    </div>
  );
}

export default Navbar;
