import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Footer from "./Footer";

function Newsletter() {
  const MAILCHIMP_URL =
    "https://agency.us12.list-manage.com/subscribe/post?u=61b6f6ff3bec843aeefe7dc3f&amp;id=fa089f758a&amp;f_id=009fbde0f0";
  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <Footer
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        );
      }}
    />
  );
}

export default Newsletter;
