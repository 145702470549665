import React from "react";
import Carousel from "../../Components/Carousel";
import "./index.css";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Lazy } from "swiper";
import VisibilitySensor from "react-visibility-sensor";
// Import Swiper styles
import "swiper/css";
function Projects() {
  return (
    <div className="Projects">
      <div className="column">
        <div className="project" id="Projects">
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <div className="container">
              <div className="project-title">
                <h1>Recent Clients</h1>
              </div>

              <Swiper
                spaceBetween={10}
                slidesPerView={5}
                autoplay={{
                  delay: 1,
                }}
                speed={2000}
                modules={[Autoplay, Pagination, Navigation, Lazy]}
                loop={true}
                initialSlide={0}
                centeredSlides={false}
                breakpoints={{
                  960: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                allowTouchMove={false}
                grabCursor={false}
              >
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059856382247047178/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059857054862409788/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059857454474727565/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858151731646615/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://cdn.discordapp.com/attachments/1035139393150787675/1059858271755849789/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858381403336725/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858619794993184/F41B557C-69B3-4906-A241-92946FAD53B4.png"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858764049694750/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858873021903018/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059858972477227118/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059859093872988221/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div style={{ padding: 8 }}>
                    <img
                      src="https://media.discordapp.net/attachments/1035139393150787675/1059859646392832020/image0.jpg"
                      alt="placeholder"
                      style={{ width: "150px" }}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <h1>Access to</h1>
              <div className="row">
                <div className="column">
                  <h2>
                    <CountUp end={30}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <h2>Launchpads</h2>
                </div>
                <div className="column">
                  <h2>
                    <CountUp end={340}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <h2>VCs</h2>
                </div>
                <div className="column">
                  <h2>
                    <CountUp end={200}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <h2>KOLs</h2>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
}

export default Projects;
