import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../../Components/Footer";
import Newsletter from "../../Components/Newsletter";
import "./index.css";

function Contact() {
  const [enabled, setEnabled] = useState(false);
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { email, message } = e.target.elements;
    let details = {
      email: email.value,
      message: message.value,
    };
    let response = await fetch(
      "https://tenreck.api.premiumplus.agency/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      }
    );
    setStatus("Submit");
    let result = await response.json();
    console.log(result.status);
    setStatus(result.status);

    setEnabled(true);
  };

  return (
    <div className="Contact" id="Contact">
      <div className="container">
        {/* <div className="title">
          <h1>Lets work together</h1>
        </div>
        <div className="row">
          <div className="video">
            <video id="cdm-vid" className="cdm-vid lazy" muted autoPlay loop>
              <source
                src="https://aplusventures.io/wp-content/uploads/2022/02/1079323061-hd.mov"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="form">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-input-material">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  autoComplete="on"
                  className="form-control-material"
                  required
                />
                <label htmlFor="Name">Email</label>
              </div>
              <div className="form-input-material">
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Message"
                  autoCorrect="on"
                  className="form-control-material"
                  required
                  cols="20"
                  rows="5"
                ></textarea>
                <label htmlFor="msg">Write a message</label>
              </div>
              <p>
                I agree with <a href="/pollicy">Privacy policy</a>
              </p>
              <div className="button-contact">
                <button type="submit" className="button" disabled={enabled}>
                  {status}
                </button>
              </div>
            </form>
          </div> 
        </div>*/}
        <div className="column">
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <p style={{letterSpacing: '5px'}}>HIRE US</p>
          </ScrollAnimation>
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <h2 className="title">SAVE TIME SAVE MONEY</h2>
          </ScrollAnimation>
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <p>Get in touch today and let's build the WEB3 space together.</p>
          </ScrollAnimation>
        </div>
        <div className="row">
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <div className="button-contactwhite">
              <form action="https://calendly.com/razvan-paleacu/30min">
                <button type="submit" className="button">
                  SETUP A MEETING{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </ScrollAnimation>
          <ScrollAnimation delay={100} animateIn="fadeIn">
            <div className="button-contactwhite">
              <form action="https://t.me/andreicarabas">
                <button type="submit" className="button">
                  CONTACT US{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </ScrollAnimation>
        </div>
        <ScrollAnimation delay={100} animateIn="fadeIn">
          <div className="line"></div>
        </ScrollAnimation>
        <ScrollAnimation delay={100} animateIn="fadeIn">
          <div
            className="social-contact"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href="https://twitter.com/ThePremiumPlus"
              target="_blank"
              title="Github"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M22.99121,3.9502a.99942.99942,0,0,0-1.51074-.85938,7.47956,7.47956,0,0,1-1.873.793,5.15234,5.15234,0,0,0-3.374-1.24219,5.23238,5.23238,0,0,0-5.22363,5.06348A11.03194,11.03194,0,0,1,4.19629,3.78125,1.01154,1.01154,0,0,0,3.33887,3.416a.99852.99852,0,0,0-.78516.5,5.2755,5.2755,0,0,0-.24219,4.76855l-.00195.00195a1.0411,1.0411,0,0,0-.49512.88868,3.04174,3.04174,0,0,0,.02637.43945,5.1854,5.1854,0,0,0,1.56836,3.3125.99813.99813,0,0,0-.06641.76953,5.20436,5.20436,0,0,0,2.36231,2.92187,7.46464,7.46464,0,0,1-3.58985.44825.99975.99975,0,0,0-.665,1.833A12.94248,12.94248,0,0,0,8.46,21.36133,12.7878,12.7878,0,0,0,20.9248,11.998,12.82166,12.82166,0,0,0,21.46,8.35156c0-.06543,0-.13281-.001-.20019A5.76963,5.76963,0,0,0,22.99121,3.9502ZM19.68457,7.16211a.995.995,0,0,0-.2334.70215c.00977.165.00879.331.00879.4873a10.82371,10.82371,0,0,1-.4541,3.08106A10.68457,10.68457,0,0,1,8.46,19.36133a10.93791,10.93791,0,0,1-2.55078-.30078,9.47951,9.47951,0,0,0,2.94238-1.56348A1.00033,1.00033,0,0,0,8.25,15.71094a3.208,3.208,0,0,1-2.21387-.93457q.22413-.04248.44532-.10547a1.00026,1.00026,0,0,0-.08008-1.94336,3.19824,3.19824,0,0,1-2.25-1.72559,5.29929,5.29929,0,0,0,.54492.0459,1.02093,1.02093,0,0,0,.9834-.69629A.9998.9998,0,0,0,5.2793,9.21484,3.19559,3.19559,0,0,1,3.85547,6.542c0-.0664.00195-.13281.00586-.19824a13.01365,13.01365,0,0,0,8.209,3.47949,1.02046,1.02046,0,0,0,.81739-.3584,1.00037,1.00037,0,0,0,.206-.86816,3.15653,3.15653,0,0,1-.08691-.72852A3.23,3.23,0,0,1,16.2334,4.6416a3.18428,3.18428,0,0,1,2.34472,1.02051A.993.993,0,0,0,19.499,5.96a9.27073,9.27073,0,0,0,1.21192-.32226A6.68126,6.68126,0,0,1,19.68457,7.16211Z"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/premiumplusagency/"
              target="_blank"
              title="instagram"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M17.5,8.999a5.41868,5.41868,0,0,0-2.56543.64453A.99918.99918,0,0,0,14,8.999H10a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-5.5a1,1,0,1,1,2,0v5.5a.99942.99942,0,0,0,1,1h4a.99942.99942,0,0,0,1-1v-7.5A5.50685,5.50685,0,0,0,17.5,8.999Zm3.5,12H19v-4.5a3,3,0,1,0-6,0v4.5H11v-10h2v.70313a1.00048,1.00048,0,0,0,1.78125.625A3.48258,3.48258,0,0,1,21,14.499Zm-14-12H3a.99943.99943,0,0,0-1,1v12a.99942.99942,0,0,0,1,1H7a.99942.99942,0,0,0,1-1v-12A.99943.99943,0,0,0,7,8.999Zm-1,12H4v-10H6ZM5.01465,1.542A3.23283,3.23283,0,1,0,4.958,7.999h.02832a3.23341,3.23341,0,1,0,.02832-6.457ZM4.98633,5.999H4.958A1.22193,1.22193,0,0,1,3.58887,4.77051c0-.7461.55957-1.22852,1.42578-1.22852A1.2335,1.2335,0,0,1,6.41113,4.77051C6.41113,5.5166,5.85156,5.999,4.98633,5.999Z"
                />
              </svg>
            </a>
          </div>
        </ScrollAnimation>
      </div>
      <Newsletter />
    </div>
  );
}

export default Contact;
