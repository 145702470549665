import React from "react";
import "./card.css";

function Card(props) {
  return (
    <div className="card">
      <div className="row">
        <p className="index">/0{props.index}</p>
      </div>
      <div className="column">
        <div className="img">
          <img src={props.img} alt="" />
        </div>
        <div className="content">
          <div className="card-title">
            <h1>{props.title}</h1>
          </div>
          <div className="card-description">
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
